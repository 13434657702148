@import './buttons';
@import './teams';
@import './mixins';
@import './variables';

.fdb-block {
  font-family: 'Roboto', sans-serif;
  font-size: $font-size;
  line-height: $line-height;
  @include font-smoothing(on);
  text-rendering: optimizelegibility;
  padding: $block-padding 0;
  color: $font-color;
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  background-color: $white;

  &.fdb-viewport {
    min-height: calc(100% - 2 * #{$block-padding});

    .container {
      min-height: calc(100% - 2 * #{$block-padding});
    }
  }

  &.fdb-image-bg {
    color: mix($black, $white, 5%);
  }

  .fdb-box {
    background: $white;
    color: $font-color;
    padding: 60px 40px;
    @include border-radius(4px);
    @include froala-box-shadow(1);
    overflow: hidden;
  }

  .fdb-touch {
    border-top: solid 5px $primary-color;
  }

  // Headings
  @each $h, $v in $headings {
    #{$h}, .text-#{$h} {
      font-size: $v;
      margin-bottom: .5em;
      margin-top: .3em;
      font-weight: 400;
    }
  }

  .font-weight-light {
    font-weight: 300;
  }

  img {
    @include border-radius(4px);

    + p, + h3, + h4 {
      margin-top: 20px;
    }

    + h1, + h2 {
      margin-top: 40px;
    }
  }

  .col-fill-left {
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: 1;

    + div {
      position: relative;
      z-index: 2;
    }
  }

  .col-fill-right {
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: 1;

    + div {
      position: relative;
      z-index: 2;
    }
  }

  img.fdb-icon {
    width: auto;
    width: 60px;
  }

  img.fdb-icon-round {
    width: auto;
    width: 60px;
    @include border-radius(60px);
  }

  .row-100 {
    height: 100px;
    width: 100%;
  }

  .row-50 {
    height: 50px;
    width: 100%;
  }

  .row-70 {
    height: 70px;
    width: 100%;
  }
}

footer {
  padding: 30px 0 !important;

  &.footer-large {
    padding: 100px 0 !important
  }

  .flex-column {
    .nav-link {
      padding-left: 0;
    }
  }

  &.bg-dark {
    background: #2d313c;
    color: mix($black, $white, 5%);

    a {
      color: mix($black, $white, 5%);
    }
  }

  a {
    color: $font-color;
  }
}

header {
  background-color: $white;

  .navbar-nav {
    a.nav-link {
      color: #777;

      &:hover {
        color: $primary-color;
      }
    }

    .active {
      a.nav-link {
        color: $font-color;
      }
    }
  }

  .navbar-toggler-icon {
  	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  &.bg-dark {
    .navbar-nav {
      a.nav-link {
        color: #dcdcdc;

        &:hover {
          color: $primary-color;
        }
      }

      .active {
        a.nav-link {
          color: $white;
        }
      }
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }
}

header + header {
  border-top: solid 1px #EEE;
}

.bg-dark {
  background-color: #2d313c;
  color: mix($black, $white, 5%);
}

.bg-gray {
  background-color: #fafafa;
  color: $font-color;
}

.br {
  @include border-radius(4px);
  overflow: hidden;
}

.br-0 {
  @include border-radius(0 !important);
}

.heart {
  color: #df584e;
  font-weight: bold;
}

*.text-light {
  font-weight: 300 !important;
}

.sl-1 {
  @include froala-box-shadow(1);
  z-index: 2;
}

table {
  tr.no-border {
    th, td {
      border-top: 0;
    }
  }
}
